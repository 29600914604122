import React from 'react';
import { TextService } from 'services/TextService';
import { ApiService } from 'services/ApiService';
import { DashboardDataService } from 'services/DashboardDataService';
import { ICardInfo } from 'services/ICardInfo';
import { setIsLoading } from 'services/LoadingIndicator';
import strings from 'VistoWebPartStrings';
import { DashboardPlanTree } from './DashboardPlanTree';
import { SearchBox, Stack, useTheme, ActionButton, Pivot, PivotItem, CommandButton, IContextualMenuProps, Toggle, DefaultButton } from '@fluentui/react';
import { Placeholder } from '@pnp/spfx-controls-react/lib/controls/placeholder';
import { api } from 'shared/api';
import { StorageService } from 'services/StorageService';
import { DeletePlanDialog } from 'dialogs/common/DeletePlanDialog';
import { EnvContext, isTeams } from 'services/EnvContext';
import { DashboardPlanList } from './DashboardPlanList';
import { StatusBarZoom } from 'frames/TopFrame/statusbar/StatusBarZoom';
import { ViewAlignment } from 'frames/TopFrame/drawing/common/mx';
import { TopEditButton } from '../../components/TopEditButton';
import { useNotifications } from 'frames/hooks/NotificationsHook';
import { NotificationType } from 'services/Notify';
import { NotificationBar } from 'components/NotificationBar';
import './DashboardFrame.module.scss';
import { UserInfoService } from 'services/UserInfoService';
import { trackClient } from 'shared/clientTelemetry';
import { UrlService } from 'shared/urlService';
import { TreeService } from 'services/TreeService';
import { TopFilter } from 'components/TopFilter';
import { LicenseService } from 'services/LicenseService';

enum SortOrder {
  Recent = 1,
  Modified = 2,
  Name = 3,
}

export const DashboardFrame = (props: {
  onAddNew: () => void;
}) => {

  const [allCardInfos, setAllCardInfos] = React.useState<ICardInfo[]>([]);
  const [showPlaceholder, setShowPlaceholder] = React.useState(false);

  const theme = useTheme();

  const notifications = useNotifications();

  const { hostKind, userObjectId, tid } = React.useContext(EnvContext);

  const onConnect = (source: ICardInfo, target: ICardInfo) => {
    setIsLoading(TextService.format(strings.DashboardCommand_Connect));
    ApiService.connectParentChild(source.siteUrl, source.planId, target.key).then(() => {
      const newCardInfos = [...allCardInfos];
      for (const ci of newCardInfos) {
        if (ci.key === source.key) {
          ci.children.push({
            childRef: target.key,
            fixed: true,
          });
        }
      }
      setAllCardInfos(newCardInfos);
    }).catch(error => {
      notifications.addNotification({ type: NotificationType.error, message: TextService.format(strings.DashboardCommand_ConnectError), error });
    }).finally(() => {
      setIsLoading('');
    });
  }

  const onDisconnect = (source: ICardInfo, target: ICardInfo) => {
    setIsLoading(TextService.format(strings.DashboardCommand_Disconnect));
    ApiService.disconnectParentChild(source.siteUrl, source.planId, target.key).then(() => {
      const newCardInfos = [...allCardInfos];
      for (const ci of newCardInfos) {
        if (ci.key === source.key) {
          ci.children = ci.children.filter(c => c.childRef !== target.key);
        }
      }
      setAllCardInfos(newCardInfos);
    }).catch(error => {
      notifications.addNotification({ type: NotificationType.error, message: TextService.format(strings.DashboardCommand_DisconnectError), error });
    }).finally(() => {
      setIsLoading('');
    });
  }

  const onTogglePinned = (ci: ICardInfo) => {
    setIsLoading(TextService.format(strings.DashboardCommand_Pin));
    ApiService.markPlanPinned(ci.planId, ci.channelId, !ci.pinned).then(pinned => {
      ci.pinned = pinned;
      setAllCardInfos([...allCardInfos]);
    }).catch(error => {
      notifications.addNotification({ type: NotificationType.error, message: TextService.format(strings.DashboardCommand_PinError), error });
    }).finally(() => {
      setIsLoading('');
    });
  };

  const [planToDelete, setPlanToDelete] = React.useState<ICardInfo>(null);

  const deleteCard = (cards: ICardInfo[], key: string) => {
    const newCardInfos = [];
    for (const ci of cards) {
      if (ci.key !== key) {
        newCardInfos.push(ci);
        if (ci.children) {
          ci.children = ci.children.filter(c => c.childRef !== key);
        }
      }
    }
    return newCardInfos;
  }

  const onDelete = async () => {
    setIsLoading(TextService.format(strings.DashboardCommand_Delete));

    if (planToDelete.missing && !UrlService.isLocalUrl(planToDelete.siteUrl)) {
      try {
        const done = await ApiService.markPlanDeleted(planToDelete.planId, planToDelete.channelId);
        if (done) {
          const newCardInfos = deleteCard(allCardInfos, planToDelete.key);
          setAllCardInfos(newCardInfos);
          setShowPlaceholder(newCardInfos.length === 0);
        }
      } catch (error) {
        notifications.addNotification({ type: NotificationType.error, message: TextService.format(strings.DashboardCommand_MarkDeletedError), error });
      } finally {
        setIsLoading('');
      }
    }
    else {
      try {
        await StorageService.get(planToDelete.siteUrl).deletePlanItem({ siteUrl: planToDelete.siteUrl, planId: planToDelete.planId }, false);
        const newCardInfos = deleteCard(allCardInfos, planToDelete.key);
        setAllCardInfos(newCardInfos);
        setShowPlaceholder(newCardInfos.length === 0);
      } catch (error) {
        notifications.addNotification({ type: NotificationType.error, message: TextService.format(strings.DashboardCommand_DeleteError), error });
      } finally {
        setIsLoading('');
      }
    }
     
    setPlanToDelete(null);
  };

  const loadPlans = async () => {

    setIsLoading(TextService.format(strings.DashboardProgress_Discovering));

    try {
      const loadedPlans: api.IDashboardPlanItem[] = await DashboardDataService.getRelatedPlans(tid, userObjectId);

      const loadedCardInfos = await DashboardDataService.getCardsFromPlanItems(userObjectId, loadedPlans, isTeams(hostKind));
  
      await DashboardDataService.loadAllCardDetails(loadedCardInfos, theme, notifications);
      setAllCardInfos(loadedCardInfos);
      setShowPlaceholder(loadedCardInfos.length === 0);

      if (!showTree) {
        DashboardDataService.loadPlanUserPhotos(loadedCardInfos, notifications).then(() => {
          setAllCardInfos([...loadedCardInfos]);
        });
      }

    } catch (error) {
      trackClient.error('loadPlans', error);
    } finally {
      setIsLoading('');
    }
  };

  React.useEffect(() => {
    if (tid) {
      UserInfoService.configure(tid, notifications);
    }
    loadPlans();
  }, [userObjectId, tid]);

  const [filter, _setFilter] = React.useState(localStorage.getItem('DashboardView_Filter') || '');
  const setFilter = (val: string) => {
    _setFilter(val);
    localStorage.setItem('DashboardView_Filter', val);
  };

  const [showTree, _setShowTree] = React.useState(localStorage.getItem('DashboardView_ShowTree') !== 'false');
  const setShowTree = (val: boolean) => {
    _setShowTree(val);
    if (!val) {
      const newCardInfos = [...allCardInfos];
      DashboardDataService.loadPlanUserPhotos(newCardInfos, notifications).then(() => {
        setAllCardInfos(newCardInfos);
      });
    }
    localStorage.setItem('DashboardView_ShowTree', val ? 'true' : 'false');
  };

  const [sortOrder, setSortOrder] = React.useState(SortOrder[localStorage.getItem('DashboardView_SortOrder')] || SortOrder.Name);

  const getSortOrderText = (k: SortOrder) => {
    switch (k) {
      case SortOrder.Name:
        return TextService.format(strings.DashboardCard_OrderByName);
      case SortOrder.Modified:
        return TextService.format(strings.DashboardCard_OrderByModified);
      case SortOrder.Recent:
        return TextService.format(strings.DashboardCard_OrderByRecent);
    }
  };

  const menuProps: IContextualMenuProps = {
    items: TextService.enumKeys(SortOrder).map(key => ({
      key: key,
      text: getSortOrderText(SortOrder[key]),
      onClick: () => setSortOrder(SortOrder[key])
      // iconProps: { iconName: 'Calendar' }
    }))
  };

  const filterFn = (ci: ICardInfo) => !filter
    || ci.name && ci.name.toLowerCase().includes(filter.toLowerCase()) || ci.location && ci.location.toLocaleLowerCase().includes(filter.toLocaleLowerCase())

  const sortFn = (a: ICardInfo, b: ICardInfo) => {

    if (a.pinned && !b.pinned) return -1;
    if (b.pinned && !a.pinned) return 1;

    switch (sortOrder) {
      case SortOrder.Name:
        return TextService.compareNames(a.name, b.name);
      case SortOrder.Modified:
        return TextService.compareDateTime(a.lastModified ?? a.lastAccessed, b.lastModified ?? b.lastAccessed);
      case SortOrder.Recent:
        return TextService.compareDateTime(a.lastAccessedByMe, b.lastAccessedByMe);
      default:
        return 0;
    }
  };

  const cardInfos = showTree
    ? TreeService.filterCards(allCardInfos, filterFn).sort(sortFn)
    : allCardInfos.filter(filterFn).sort(sortFn);

  const [editorUi, setEditorUi] = React.useState(null);
  const [readOnly, setReadOnly] = React.useState(true);

  const isMobile = (hostKind === 'TeamsMobile' || hostKind === 'WebMobile');

  const zoomInfoKey = 'DashboardView_Zoom';

  return (
    <Stack grow tokens={{ padding: 's1' }}>
      <NotificationBar notifications={notifications} top={45} />
      <Stack verticalAlign='center' horizontal tokens={{ childrenGap: 's2' }}>
        <CommandButton
          iconProps={{ iconName: showTree ? 'Org' : 'PreviewLink' }}
          text={isMobile ? '' : TextService.format(showTree ? strings.DashboardFrame_ButtonTree : strings.DashboardFrame_ButtonCards)}
          onClick={() => setShowTree(!showTree)}
        />
        <TopFilter placeholder={TextService.format(strings.DashboardFilter_SearchBoxPlaceholder)} value={filter} setValue={setFilter} />
        <CommandButton iconProps={{ iconName: 'Sort' }} text={isMobile ? '' : getSortOrderText(sortOrder)} menuProps={menuProps} />
        {showTree && <StatusBarZoom zoomInfoKey={zoomInfoKey} editorUi={editorUi} viewAlignmentX={ViewAlignment.Middle} viewAlignmentY={ViewAlignment.Begin} />}
        {showTree && !isMobile && <TopEditButton
          readOnly={readOnly} 
          disabled={!LicenseService.license?.editPlanTreeEnabled}
          title={LicenseService.license?.editPlanTreeEnabled ? undefined : TextService.format(strings.Message_FeatureDisabled, { feature: "Edit Plan Tree" })}
          toggleReadOnly={() => setReadOnly(!readOnly)} 
        />}
      </Stack>
      {showPlaceholder
        ? <Placeholder
          iconName='AddTo'
          iconText={TextService.format(strings.DashboardPlaceholder_NoPlans)}
          description={TextService.format(strings.DashboardPlaceholder_NoPlansDdescription)}
          buttonLabel={TextService.format(strings.DashboardPlaceholder_NoPlansButton)}
          onConfigure={props.onAddNew}
        />
        : <Stack grow>
          {showTree
            ? <DashboardPlanTree
              zoomInfoKey={zoomInfoKey}
              items={cardInfos}
              readOnly={readOnly}
              sort={sortFn}
              onConnect={onConnect}
              onDisconnect={onDisconnect}
              setEditorUi={setEditorUi}
              onTogglePinned={onTogglePinned}
              onRemove={setPlanToDelete}
            />
            : <DashboardPlanList
              items={cardInfos}
              onTogglePinned={onTogglePinned}
              onDelete={setPlanToDelete}
            />}

        </Stack>
      }
      {
        !showPlaceholder &&
        <ActionButton style={{ margin: 'm' }} iconProps={{ iconName: 'Add' }} text={TextService.format(strings.Dashboard_AddNewPlanButtonLabel)} onClick={props.onAddNew} />
      }
      {
        planToDelete &&
        <DeletePlanDialog onCommit={onDelete} onDismiss={() => setPlanToDelete(null)} />
      }
    </Stack>
  );
};
